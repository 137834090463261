import { ContainerFullWidth, ContainerMaxWidth } from '@hypersay/ui';
import React from 'react';
import SEO from '../components/seo';
import T from '../components/T';
import { Link } from '../components/Link';
import Layout from '../layouts/default';

import * as styles from './terms.module.scss';

const Terms = () => (
  <Layout>
    <SEO title="Terms - Hypersay" />
    <ContainerFullWidth>
      <ContainerMaxWidth maxWidth="40em" className={styles.container}>
        <h1>
          <T id="Terms of Use" />
        </h1>
        <ol>
          <li>
            <h4>Your relationship with Hypersay</h4>
            <ol>
              <li>
                Your use of the Hypersay website (the "Website") and any
                Hypersay products, channels, software, data feeds and services
                provided to you on or from or through the Website by Hypersay
                (collectively the "Service") is subject to the terms of a legal
                agreement between you and Hypersay. "Hypersay" is a product
                created and managed by Learn Forward UK, whose principal place
                of business is at 71 Queen Victoria Street, London, EC4V 4BE,
                United Kingdom. For any notification contact us at&nbsp;
                <a
                  href="mailto:hello@hypersay.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hello@hypersay.com
                </a>
                .
              </li>
              <li>
                Your legal agreement with Hypersay is made up of the terms and
                conditions set out in this document and in our{' '}
                <Link to="/privacy/">Privacy Policy</Link> (collectively
                referred to as "Terms").
              </li>
              <li>
                The Terms form a legally binding agreement between you and
                Hypersay in relation to your use of the Service. It is important
                that you take the time to read them carefully.
              </li>
              <li>
                The Terms apply to all users of the Service, including users who
                are also contributors of Content, on the Service. "Content"
                includes the presentation, text, software, scripts, graphics,
                photos, sounds, music, videos, audiovisual combinations,
                interactive features and other materials you may view on, access
                through or contribute to the Service.
              </li>
            </ol>
          </li>
          <li>
            <h4>Accepting the Terms</h4>
            <ol>
              <li>
                In order to use the Service, you must firstly agree to the
                Terms. You may not use the Service if you do not accept the
                Terms.
              </li>
              <li>
                You can accept the Terms by simply using the Service. You
                understand and agree that Hypersay will treat your use of the
                Service as acceptance of the Terms from that point onwards.
              </li>
              <li>You are at least 16 years old ("legal age").</li>
              <li>
                You may not use the Service and may not accept the Terms if (a)
                you are not of legal age to form a binding contract with
                Hypersay, or (b) you are a person who is either barred or
                otherwise legally prohibited from receiving or using the Service
                under the laws of the country in which you are resident or from
                which you access or use the Service.
              </li>
              <li>
                You should print off or save a local copy of the Terms for your
                records.
              </li>
            </ol>
          </li>
          <li>
            <h4>Changes to the Terms</h4>
            Hypersay reserves the right to make changes to the Terms from time
            to time, for example to address changes to the law or regulatory
            changes or changes to functionality offered through the Service.
            Therefore you must look at the Terms regularly to check for such
            changes. The modified version of the Terms (the "Modified Terms")
            will be posted at{' '}
            <a href="https://hypersay.com/terms/">hypersay.com/terms</a> or made
            available within the Service (for any modified additional terms). If
            you do not agree to the Modified Terms you must stop using the
            Service. Your continued use of the Service after the date the
            Modified Terms are posted will constitute your acceptance of the
            Modified Terms.
          </li>
          <li>
            <h4>Hypersay accounts</h4>
            <ol>
              <li>
                In order to access some features of the Website or other
                elements of the Service, you will have to create a Hypersay
                account. When creating your account, you must provide accurate
                and complete information. It is important that you must keep
                your Hypersay account details secure and confidential.
              </li>
              <li>
                You must notify Hypersay immediately of any breach of security
                or unauthorised use of your Hypersay account that you become
                aware of.
              </li>
              <li>
                You agree that you will be solely responsible (to Hypersay, and
                to others) for all activity that occurs under your Hypersay
                account.
              </li>
            </ol>
          </li>
          <li>
            <h4>General restrictions on use</h4>
            <ol>
              <li>
                Hypersay hereby grants you permission to access and use the
                Service, subject to the following express conditions, and you
                agree that your failure to adhere to any of these conditions
                shall constitute a breach of these Terms on your part:
                <ul>
                  <li>
                    you agree not to distribute any part of or parts of the
                    Website or the Service, including but not limited to any
                    Content, in any medium without Hypersay's prior written
                    authorisation, unless Hypersay makes available the means for
                    such distribution through functionality offered by the
                    Service;
                  </li>
                  <li>
                    you agree not to alter or modify any part of the Website or
                    any of the Service;
                  </li>
                  <li>
                    you agree not to access Content through any technology or
                    means other than the ones provided through the website:{' '}
                    <a
                      href="https://hypersay.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      hypersay.com
                    </a>{' '}
                    (including pages, live session app and other parts of the
                    web app);
                  </li>
                  <li>
                    you agree not to (or attempt to) circumvent, disable or
                    otherwise interfere with any security related features of
                    the Service or features that (i) prevent or restrict use or
                    copying of Content or (ii) enforce limitations on use of the
                    Service or the content accessible via the Service;
                  </li>
                  <li>
                    you agree not to use the Service for any of the following
                    commercial uses unless you obtain Hypersay's prior written
                    approval: (i)the sale of access to the Service; (ii) the
                    sale of advertising, sponsorships or promotions placed on or
                    within the Service, or Content; (iii) the sale of
                    advertising, sponsorships or promotions on any page of an
                    ad-enabled blog or website containing Content delivered via
                    the Service unless other material not obtained from Hypersay
                    appears on the same page and is of sufficient value to be
                    the basis for such sales
                  </li>
                  <li>
                    prohibited commercial uses shall not include: (i) uploading
                    an original presentation to Hypersay, ii) showcasing the
                    presentations in live sessions, including added interactions
                    as provided by Hypersay and adapted / modified by you in
                    order to promote a business or artistic enterprise; and
                    (iii) any use that is expressly authorised by Hypersay in
                    writing;
                  </li>
                  <li>
                    you agree not to use or launch any automated system
                    (including, without limitation, any robot, spider or offline
                    reader) that accesses the Service in a manner that sends
                    more request messages to the Hypersay servers in a given
                    period of time than a human can reasonably produce in the
                    same period by using a publicly available, standard (i.e.
                    not modified) web browser;
                  </li>
                  <li>
                    you agree not to collect or harvest any personal data of any
                    user of the Website or any Service (and agree that this
                    shall be deemed to include Hypersay account names);
                  </li>
                  <li>
                    you agree not to use the Website or the Services (including
                    the comments, notes and email features in the Website) for
                    the solicitation of business in the course of trade or in
                    connection with a commercial enterprise;
                  </li>
                  <li>
                    you agree not to solicit, for commercial purposes, any users
                    of the Website with respect to their Content;and{' '}
                  </li>
                  <li>
                    you agree not to access Content or any reason other than
                    your personal, non-commercial use solely as intended through
                    and permitted by the normal functionality of the Service.
                  </li>
                  <li>
                    You shall not copy, reproduce, distribute, transmit,
                    broadcast, display, sell, license, or otherwise exploit any
                    Content for any other purposes without the prior written
                    consent of Hypersay or the respective licensors of the
                    Content.
                  </li>
                </ul>
              </li>
              <li>
                You agree that you will comply with all of the other provisions
                of the Terms at all times during your use of the Service.
              </li>
              <li>
                Hypersay grants the operators of public search engines
                permission to use spiders to copy materials from the site for
                the sole purpose of creating publicly available searchable
                indices of the materials, but not caches or archives of such
                materials. Hypersay reserves the right to revoke these
                exceptions either generally or in specific cases.
              </li>
              <li>
                Hypersay is constantly innovating in order to provide the best
                possible experience for its users. You acknowledge and agree
                that the form and nature of the Service which Hypersay provides
                may change from time to time without prior notice to you.
              </li>
              <li>
                As part of this continuing innovation, you acknowledge and agree
                that Hypersay may stop (permanently or temporarily) providing
                the Service (or any features within the Service) to you or to
                users generally at Hypersay's sole discretion, without prior
                notice to you. You may stop using the Service at any time. You
                do not need to specifically inform Hypersay when you stop using
                the Service.
              </li>
              <li>
                You agree that you are solely responsible for (and that Hypersay
                has no responsibility to you or to any third party for) any
                breach of your obligations under the Terms and for the
                consequences (including any loss or damage which Hypersay may
                suffer) of any such breach.
              </li>
            </ol>
          </li>
          <li>
            <h4>Copyright policy</h4>
            As part of Hypersay's copyright policy, Hypersay will terminate user
            access to the Service if a user has been determined to be a repeat
            infringer. A repeat infringer is a user who has been notified of
            infringing activity more than twice.
          </li>
          <li>
            <h4>Content</h4>
            <ol>
              <li>
                As a Hypersay account holder you may submit Content (including,
                but not limited to, uploaded presentations, embedded video,
                texts and any interactive questions / content). You understand
                that whether or not Content is published, Hypersay does not
                guarantee any confidentiality with respect to Content.
              </li>

              <li>
                You retain all of your ownership rights in your Content, but you
                are required to grant limited licence rights to Hypersay and
                other users of the Service (including participants). These are
                described in paragraph 8 of these Terms (Rights you licence).
              </li>

              <li>
                You understand and agree that you are solely responsible for
                your own Content and the consequences of posting or publishing
                it. Hypersay does not endorse any Content or any opinion,
                recommendation, or advice expressed therein, and Hypersay
                expressly disclaims any and all liability in connection with
                Content.
              </li>

              <li>
                You represent and warrant that you have (and will continue to
                have during your use of the Service) all necessary licenses,
                rights, consents, and permissions which are required to enable
                Hypersay to use your Content for the purposes of the provision
                of the Service by Hypersay, and otherwise to use your Content in
                the manner contemplated by the Service and these Terms.
              </li>

              <li>
                You agree that you will not post or upload any Content which
                contains material which it is unlawful for you to possess in the
                country in which you are resident, or which it would be unlawful
                for Hypersay to use or possess in connection with the provision
                of the Service.
              </li>

              <li>
                You agree that Content you submit to the Service will not
                contain any third party copyright material, or material that is
                subject to other third party proprietary rights (including
                rights of privacy or rights of publicity), unless you have a
                formal licence or permission from the rightful owner, or are
                otherwise legally entitled, to post the material in question and
                to grant Hypersay the licence referred to in paragraph 8.1
                below.
              </li>

              <li>
                On becoming aware of any potential violation of these Terms,
                Hypersay reserves the right (but shall have no obligation) to
                decide whether Content complies with the content requirements
                set out in these Terms and may remove such Content and/or
                terminate a User's access for uploading Content which is in
                violation of these Terms at any time, without prior notice and
                at its sole discretion.
              </li>

              <li>
                You further understand and acknowledge that in using the
                Service, you may be exposed to Content that is factually
                inaccurate, offensive, indecent, or otherwise objectionable to
                you. You agree to waive, and hereby do waive, any legal or
                equitable rights or remedies you have or may have against
                Hypersay with respect to any such Content.
              </li>
            </ol>
          </li>
          <li>
            <h4>Rights you licence</h4>
            <ol>
              <li>
                When you upload or post Content to Hypersay, you grant:
                <ul>
                  <li>
                    to Hypersay, a worldwide, non-exclusive, royalty-free,
                    transferable licence (with right to sub-licence) to use,
                    reproduce, distribute, prepare derivative works of, display,
                    and perform that Content in connection with the provision of
                    the Service and otherwise in connection with the provision
                    of the Service and Hypersay's business, including without
                    limitation for promoting and redistributing part or all of
                    the Service (and derivative works thereof) in any media
                    formats and through any media channels;
                  </li>
                  <li>
                    to each user of the Service, a worldwide, non-exclusive,
                    royalty-free licence to access your Content through the
                    Service, and to use, reproduce, distribute, prepare
                    derivative works of, display and perform such Content to the
                    extent permitted by the functionality of the Service and
                    under these Terms.
                  </li>
                </ul>
              </li>
              <li>
                The above licenses granted by you in Content terminate when you
                remove or delete your Content from the Website. The above
                licenses granted by you in textual comments you submit as
                Content are perpetual and irrevocable, but are otherwise without
                prejudice to your ownerships rights, which are retained by you
                as set out in paragraph 7.2 above.
              </li>
            </ol>
          </li>
          <li>
            <h4>Hypersay content on the Website</h4>
            With the exception of Content submitted to the Service by you, all
            other Content on the Service is either owned by or licensed to
            Hypersay, and is subject to copyright, trade mark rights, and other
            intellectual property rights of Hypersay or Hypersay's licensors.
            Any third party trade or service marks present on Content not
            uploaded or posted by you are trade or service marks of their
            respective owners. Such Content may not be downloaded, copied,
            reproduced, distributed, transmitted, broadcast, displayed, sold,
            licensed, or otherwise exploited for any other purpose whatsoever
            without the prior written consent of Hypersay or, where applicable,
            Hypersay's licensors. Hypersay and its licensors reserve all rights
            not expressly granted in and to their Content.
          </li>
          <li>
            <h4>Links from Hypersay</h4>
            <ol>
              <li>
                The Service may include hyperlinks to other web sites that are
                not owned or controlled by Hypersay. Hypersay has no control
                over, and assumes no responsibility for, the content, privacy
                policies, or practices of any third party websites.
              </li>
              <li>
                You acknowledge and agree that Hypersay is not responsible for
                the availability of any such external sites or resources, and
                does not endorse any advertising, products or other materials on
                or available from such web sites or resources.
              </li>
              <li>
                You acknowledge and agree that Hypersay is not liable for any
                loss or damage which may be incurred by you as a result of the
                availability of those external sites or resources, or as a
                result of any reliance placed by you on the completeness,
                accuracy or existence of any advertising, products or other
                materials on, or available from, such web sites or resources.
              </li>
              <li>
                Hypersay encourages you to be aware when you leave the Service
                and to read the terms and conditions and privacy policy of each
                other website that you visit.
              </li>
            </ol>
          </li>
          <li>
            <h4>Ending your relationship with Hypersay</h4>
            <ol>
              <li>
                The Terms will continue to apply until terminated by either you
                or Hypersay as set out below.
              </li>

              <li>
                If you want to terminate your legal agreement with Hypersay, you
                may do so by (a) notifying Hypersay at any time and (b) closing
                your Hypersay account. Your notice should be sent, in writing,
                to Hypersay's address which is set out at the beginning of these
                Terms.
              </li>

              <li>
                Hypersay may at any time terminate its legal agreement with you
                if:
                <ul>
                  <li>
                    you have breached any provision of the Terms (or have acted
                    in manner which clearly shows that you do not intend to, or
                    are unable to comply with the provisions of the Terms); or
                  </li>
                  <li>
                    Hypersay is required to do so by law (for example, where the
                    provision of the Service to you is, or becomes, unlawful);
                    or
                  </li>
                </ul>
              </li>

              <li>
                Hypersay may terminate its legal agreement with you if:
                <ul>
                  <li>
                    Hypersay is transitioning to no longer providing the Service
                    to users in the country in which you are resident or from
                    which you use the Service; or
                  </li>
                  <li>
                    the provision of the Service to you by Hypersay is, in
                    Hypersay's opinion, no longer commercially viable and in the
                    case of each of A and B of this clause 11.4 shall, where
                    possible, give reasonable notice of such termination.
                  </li>
                </ul>
              </li>

              <li>
                When these Terms come to an end, all of the legal rights,
                obligations and liabilities that you and Hypersay have benefited
                from, been subject to (or which have accrued over time whilst
                the Terms have been in force) or which are expressed to continue
                indefinitely, shall be unaffected by this cessation, and the
                provisions of paragraph 14.6 shall continue to apply to such
                rights, obligations and liabilities indefinitely.
              </li>
            </ol>
          </li>
          <li>
            <h4>Exclusion of Warranties</h4>
            <ol>
              <li>
                Nothing in the Terms shall affect any statutory rights that you
                are always entitled to as a consumer and that you cannot
                contractually agree to alter or waive.
              </li>

              <li>
                The Service is provided "as is" and Hypersay makes no warranty
                or representation to you with respect to them.
              </li>

              <li>
                In particular Hypersay does not represent or warrant to you
                that:
                <ul>
                  <li>your use of the Service will meet your requirements,</li>
                  <li>
                    your use of the Service will be uninterrupted, timely,
                    secure or free from error,
                  </li>
                  <li>
                    any information obtained by you as a result of your use of
                    the Service will be accurate or reliable, and
                  </li>
                  <li>
                    that defects in the operation or functionality of any
                    software provided to you as part of the Service will be
                    corrected.
                  </li>
                </ul>
              </li>

              <li>
                No conditions, warranties or other terms (including any implied
                terms as to satisfactory quality, fitness for purpose or
                conformance with description) apply to the Service except to the
                extent that they are expressly set out in the Terms.
              </li>
            </ol>
          </li>
          <li>
            <h4>Limitation of Liability</h4>
            <ol>
              <li>
                Nothing in these Terms shall exclude or limit Hypersay's
                liability for losses which may not be lawfully excluded or
                limited by applicable law.
              </li>

              <li>
                Subject to the overall provision in paragraph 13.1 above
                Hypersay shall not be liable to you for:
                <ul>
                  <li>
                    any indirect or consequential losses which may be incurred
                    by you. This shall include; (i) any loss of profit (whether
                    incurred directly or indirectly); (ii) any loss of goodwill
                    or business reputation; (iii) any loss of opportunity; or
                    (iv) any loss of data suffered by you;
                  </li>
                  <li>
                    any loss or damage which may be incurred by you as a result
                    of:
                    <ul>
                      <li>
                        any reliance placed by you on the completeness, accuracy
                        or existence of any advertising, or as a result of any
                        relationship or transaction between you and any
                        advertiser or sponsor whose advertising appears on the
                        Service;
                      </li>
                      <li>
                        any changes which Hypersay may make to the Service, or
                        for any permanent or temporary cessation in the
                        provision of the Service (or any features within the
                        Service);
                      </li>
                      <li>
                        the deletion of, corruption of, or failure to store, any
                        Content and other communications data maintained or
                        transmitted by or through your use of the Service;
                      </li>
                      <li>
                        your failure to provide Hypersay with accurate account
                        information;
                      </li>
                      <li>
                        your failure to keep your login credentials or Hypersay
                        account details secure and confidential.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                The limitations on Hypersay's liability to you in paragraph 13.2
                above shall apply whether or not Hypersay has been advised of or
                should have been aware of the possibility of any such losses
                arising.
              </li>
            </ol>
          </li>
          <li>
            <h4>General legal terms</h4>
            <ol>
              <li>
                The Terms constitute the whole legal agreement between you and
                Hypersay and govern your use of the Service and completely
                replace any prior agreements between you and Hypersay in
                relation to the Service.
              </li>

              <li>
                You agree that Hypersay may provide you with notices, including
                those regarding changes to the Terms, by email, regular mail, or
                postings on the Service.
              </li>

              <li>
                You agree that if Hypersay does not exercise or enforce any
                legal right or remedy which is contained in the Terms (or which
                Hypersay has the benefit of under any applicable law), this will
                not be taken to be a formal waiver of Hypersay's rights and that
                those rights or remedies will still be available to Hypersay.
              </li>

              <li>
                If any court of law, having the jurisdiction to decide on this
                matter, rules that any provision of these Terms is invalid, then
                that provision will be removed from the Terms without affecting
                the rest of the Terms. The remaining provisions of the Terms
                will continue to be valid and enforceable.
              </li>

              <li>
                The Terms, and your relationship with Hypersay under the Terms,
                shall be governed by English law. You and Hypersay agree to
                submit to the exclusive jurisdiction of the courts of England to
                resolve any legal matter arising from the Terms. Notwithstanding
                this, you agree that Hypersay shall still be allowed to apply
                for injunctive remedies (or other equivalent types of urgent
                legal remedy) in any jurisdiction.
              </li>
            </ol>
          </li>
        </ol>
      </ContainerMaxWidth>
    </ContainerFullWidth>
  </Layout>
);

export default Terms;
